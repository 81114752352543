<template>
  <section>
    <div class="ll-topbox">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Delivery Order') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Order List') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
      <b-dropdown
        :text="$t('Batch Operation')"
        variant="info"
        class="ll-batch"
        v-if="false"
      >
        <b-dropdown-item @click="batchDownload">{{$t('Download Label')}}</b-dropdown-item>
      </b-dropdown>
      <b-button
        variant="info"
        class="ll-cancel"
        :to="{ name: 'create-order' }"
        v-permission="[141]"
      >
        {{ $t('Create new') }}
      </b-button>
    </div>

    <!-- first floor -->
    <!-- <b-row>
      <b-col lg="3"> -->
    <div class="d-flex">
      <div class="mr-1">
        <b-card class="card-browser-states ll-fHei ll-card ll-width" >
          <b-card-body>
            <flat-pickr
              v-model="time"
              class="form-control ll-fpickr"
              :config="dateConfig"
              @input="onChange"
            />
            <b-input-group class="ll-searchBox">
              <b-input-group-prepend
                is-text
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle.filterS
                class="ll-outline"
                :style="filterBg">
                <!-- Filter -->
                <svg class="ll-ficon" aria-hidden="true">
                  <use xlink:href="#icon-filter"></use>
                </svg>
              </b-input-group-prepend>
              <b-input-group-prepend>
              <b-form-select
                v-model="numtype"
                :options="numList"
                @input="selnum"
                class="ll-numSel"
              />
              </b-input-group-prepend>
              <b-form-textarea
                v-model="trackingIDs"
                class="searchInput"
                :placeholder="placeholder"
                no-resize
              />
              <b-input-group-prepend
                is-text
                class="ll-searchBtn"
                :style="{'cursor': loadingList ? 'no-drop' : 'pointer'}"
                :disabled="loadingList"
                @click="searchById">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                  size="18"
                />
              </b-input-group-prepend>
            </b-input-group>
          </b-card-body>
        </b-card>
      </div>
      <!-- </b-col>
      <b-col lg="9"> -->
      <div class="flex-grow-1">
        <b-card class="card-browser-states ll-fHei" >
          <b-card-body>
            <b-row class="ll-row">
              <b-col lg="3">
                <div class="ll-newbox">
                  <b-avatar
                    size="48"
                    variant="light-secondary"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-package"></use>
                    </svg>
                  </b-avatar>
                  <div class="ll-tit">{{ orderStatusCode.rec !== undefined ? orderStatusCode.rec : 0 }}</div>
                  <div class="ll-des">{{ $t('Order Received') }}</div>
                </div>
              </b-col>
              <b-col lg="3">
                <div class="ll-newbox">
                  <b-avatar
                    size="48"
                    variant="light-warning"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-rukuguanli"></use>
                    </svg>
                  </b-avatar>
                  <div class="ll-tit">{{ orderStatusCode.prod !== undefined ? orderStatusCode.prod : 0 }}</div>
                  <div class="ll-des">{{ $t('On Warehouse') }}</div>
                </div>
              </b-col>
              <b-col lg="3">
                <div class="ll-newbox">
                  <b-avatar
                    size="48"
                    variant="light-danger"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-peisongguanli"></use>
                    </svg>
                  </b-avatar>
                  <div class="ll-tit">{{ orderStatusCode.dip !== undefined ? orderStatusCode.dip : 0 }}</div>
                  <div class="ll-des">{{ $t('Delivery In Progress') }}</div>
                </div>
              </b-col>
              <b-col lg="3">
                <div class="ll-newbox">
                  <b-avatar
                    size="48"
                    variant="light-info"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-icon-test1"></use>
                    </svg>
                  </b-avatar>
                  <div class="ll-tit">{{ orderStatusCode.comp !== undefined ? orderStatusCode.comp : 0 }}</div>
                  <div class="ll-des">{{ $t('Completed') }}</div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>
    </div>
      <!-- </b-col>
    </b-row> -->

    <!-- second floor -->
    <div class="d-flex">
    <!-- <b-row>
      <b-col lg="3"> -->
      <div class="mr-1">
        <b-overlay :show="loadingList" rounded="sm">
          <div class="ll-cardHei ll-sHei ll-width">
            <template v-if="rows.length > 0">
              <div class="ll-tBox" v-show="isTbox">
                <b-form-checkbox
                  v-model="isAllCheck"
                  class="ml-0 mt-50 mb-1"
                  inline
                  @input="selectedAll"
                  v-if="check1"
                >
                  <span class="font-weight-bolder">{{$t('Check All')}}</span>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="isAllCheck1"
                  class="ml-0 mt-50 mb-1"
                  inline
                  @input="selectedAll1"
                  v-if="check2"
                >
                  <span class="font-weight-bolder">{{$t('Check All')}}</span>
                </b-form-checkbox>
                <b-dropdown
                  :text="$t('Batch Operation')"
                  variant="info"
                  class="ll-batch"
                >
                  <b-dropdown-item @click="batchDownload">{{$t('Download Label')}}</b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="ll-cbox" id="cbox" ref="cbox">
                <b-card
                  v-for="(item, index) in rows"
                  :key="index"
                  class="card-browser-states ll-card ll-cardList ll-hover"
                  :class="item.tkID == selected ? 'bg-light' : 'bg-white'"
                  @click="onDetail(item)"
                  v-permission="[140]"
                >
                  <b-card-header>
                    <b-row class="ll-rowbox">
                      <b-col lg="3" class="ll-col1">
                        <span class="ll-tkid">{{ item.customerName }}</span>
                      </b-col>
                      <b-col lg="5" class="ll-col2">
                        <span class="ll-tkid">{{ item.customerOrderNo }}</span>
                      </b-col>
                      <b-col lg="4" class="ll-col3">
                        <!-- <div> -->
                          <b-badge
                            pill
                            class="ll-badge"
                            variant="light-info"
                            v-if="item.latestOrderStatus === 'COMP'"
                          >
                            <span>Completed</span>
                          </b-badge>
                          <b-badge
                            pill
                            class="ll-badge"
                            variant="light-secondary"
                            v-if="item.latestOrderStatus === 'REC'"
                          >
                            <span>Order received</span>
                          </b-badge>
                          <b-badge
                            pill
                            class="ll-badge"
                            variant="light-warning"
                            v-if="item.latestOrderStatus === 'PROD'"
                          >
                            <span>On Warehouse</span>
                          </b-badge>
                          <b-badge
                            pill
                            class="ll-badge"
                            variant="light-danger"
                            v-if="item.latestOrderStatus === 'DIP'"
                          >
                            <span>Delivery in progress</span>
                          </b-badge>
                        <!-- </div> -->
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-card-body class="ll-cardBody">
                    <b-form-checkbox
                      v-model="item.choose"
                      class="mr-0 mt-50"
                      inline
                      @input="itemCheck(item)"
                    />
                    <!-- <span>{{ item.latestOrderStatusTime }}</span> -->
                    <span>{{ item.pkShippingDate }}</span>
                    <span class="ll-fontbold">{{(item.orderQty === 1 || item.orderQty === null) ? '1/1' : ( (index === idx ? nums: '1') +'/'+item.orderQty) }}</span>
                    <span
                      class="ll-tkid"
                      v-if="(item.orderQty === 1 || item.orderQty === null) "
                    >
                      {{ item.tkID }}
                    </span>
                    <b-form-select
                      v-if="(item.orderQty !== 1 && item.orderQty !== null) "
                      size="sm"
                      class="ll-pg"
                      @input="name => selSonList(name, index)"
                    >
                      <b-form-select-option v-for="(i,k) in item.sonList" :key="k" :value="i">
                        <span>{{i.tkID}}</span>
                      </b-form-select-option>
                    </b-form-select>
                    <!-- <span class="ll-custom">{{ item.customerName }}</span> -->
                    <!-- <div v-for="(t, i) of item.tag" :key="i" class="ll-0">
                    <b-badge
                      pill
                      class="ll-badge"
                      v-if="t === 'CUS_COMPLETE' || t === 'DELIVERED' || t === 'SELF_PICKUP'"
                      :variant="`light-${color}`">
                      <span v-if="t !== null">{{ t.replace('_', ' ') }}</span>
                    </b-badge>
                    <b-badge
                      pill
                      class="ll-badge"
                      v-if="t === 'NO_SORT_CODE' || t === 'RESCHEDULED' || t === 'DELIVERY_DELAY' || t === 'LATE_ARRIVAL'"
                      :variant="`light-${color1}`">
                      <span v-if="t !== null">{{ t.replace(/_/g, ' ') }}</span>
                    </b-badge>
                    <b-badge
                      pill
                      class="ll-badge"
                      v-if="t === 'REJECTED' || t === 'DAMAGED' || t === 'UNDELIVERABLE' || t === 'LOST' || t === 'DAMAGED' || t === 'SCRAPPED'"
                      :variant="`light-${color2}`">
                      <span v-if="t !== null">{{ t.replace('_', ' ') }}</span>
                    </b-badge>
                    <b-badge
                      pill
                      class="ll-badge"
                      v-if="t === 'REMOTE_AREA' || t === 'RETURNED'"
                      :variant="`light-${color3}`">
                      <span v-if="t !== null">{{ t.replace('_', ' ') }}</span>
                    </b-badge>
                  </div> -->
                  </b-card-body>
                </b-card>
              </div>
            </template>
            <div v-else class="ll-sHei d-flex align-items-center justify-content-center">
              <h2>{{ $t('No order found') }}</h2>
            </div>
          </div>
          <!-- 分页 -->
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex flex-column align-items-center mb-0 mt-1">
              <div class="d-flex">
                <span class="text-nowrap" style="margin:auto">Showing </span>
                <b-form-select
                  v-model="size"
                  :options="pageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap" style="margin:auto">entries per page, total {{ totalSize }} entries</span>
              </div>
              <div class="mt-1">
                <b-pagination
                  v-model="page"
                  :total-rows="totalSize"
                  :per-page="size"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
      <!-- </b-col>
      <b-col lg="9"> -->
      <div class="flex-grow-1">
        <b-card class="card-browser-states ll-backg ll-sHei ll-cardHei1">
          <b-card-body>
            <b-tabs
              pills
              class="ll-tabs"
              v-if="selectedItem"
            >
              <!-- Tab: Overview -->
              <b-tab active>
                <template #title>
                  <span class="d-none d-sm-inline">{{ $t('Overview') }}</span>
                </template>
                <overview :orderItem="selectedItem"
                  :jobDetails1="jobDetails1"
                  :jobDetails2="jobDetails2"
                  :jobDetails3="jobDetails3"
                  :jobDetails4="jobDetails4"
                ></overview>
              </b-tab>

              <!-- Tab: Item -->
              <b-tab>
                <template #title>
                  <span class="d-none d-sm-inline">{{ $t('Item') }}</span>
                </template>
                <item
                  :jobDetails1="jobDetails1"
                  :jobDetails2="jobDetails2"
                  :jobDetails5="jobDetails5"
                >
                </item>
              </b-tab>

              <!-- Tab: Operation -->
              <b-tab>
                <template #title>
                  <span class="d-none d-sm-inline" v-permission="[208]">{{ $t('Operation') }}</span>
                </template>
                <operation
                  :jobDetails1="jobDetails1"
                  :jobDetails2="jobDetails2"
                  :jobDetails3="jobDetails3"
                ></operation>
              </b-tab>
            </b-tabs>
            <div class="display-4 ll-sHei d-flex align-items-center justify-content-center" v-else>
              {{ $t('Please select an order to view') }}
            </div>
          </b-card-body>
        </b-card>
      </div>
      <!-- </b-col>
    </b-row> -->
    </div>

    <!-- Filters sidebar -->
    <b-sidebar id="filterS" title=""
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3">
        <app-collapse class="ll-collapse">
          <app-collapse-item :title="$t('Customer')" v-if="isAccess">
            <v-select
              v-model="customer"
              :options="customerList"
              label="name"
              @input="selCustomer"
              placeholder="Please Select Customer"
            ></v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Status')">
            <v-select
              id="status"
              v-model="latestOrderStatus1"
              :options="statusList"
              label="name"
              @input="selStatus"
              placeholder="Please Select Status"
            ></v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Tags')">
            <v-select
              id="tags"
              v-model="tags"
              multiple
              :options="tagsList"
              label="codeDescription"
              @input="selTag"
              placeholder="Please Select Tags"
            ></v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Service Party')">
            <v-select
              id="serviceParty"
              v-model="serviceParty"
              :options="sPartyList"
              @input="selSParty"
              label="codeDescription"
              placeholder="Please Select Service Party"
            ></v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Delivery method')">
            <v-select :options="typeList" @input="selType" label="key" v-model="condition.deliveryType">
              <template #option="{ key, des }">
                <span class="ml-50 align-middle"> {{ key }}({{ des}})</span>
              </template>
            </v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Customer Type')">
            <v-select
              v-model="cusType"
              :options="cusTypeList"
              label="codeDescription"
              @input="selCusType"
              placeholder=""
            ></v-select>
          </app-collapse-item>
          <!-- <app-collapse-item :title="$t('Misc')">
            <b-form-checkbox
              v-model="pkg"
            >
              {{ $t('multiple packages') }}
            </b-form-checkbox>
          </app-collapse-item> -->
        </app-collapse>
        <div class="ll-btn">
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear"
          >
            {{ $t('Clear') }}
          </b-button>
          <b-button variant="info"
            v-b-toggle.filterS
            @click="searchById">
            {{ $t('Search') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BFormTextarea,
  BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
  BButton, BSidebar, BInputGroupPrepend, BInputGroup,
  BCardHeader, BCardTitle, BCardBody, VBToggle, BTab, BTabs,
  BPagination, BFormSelect, BAvatar, BFormCheckbox, BFormSelectOption, BModal, VBModal,
  BDropdown, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vSelect from 'vue-select'
import Overview from './components/overview.vue'
import Operation from './components/operation.vue'
import Item from './components/item.vue'
import { debounce } from "@/libs/fun.js"
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    Overview,
    Operation,
    Item,
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BButton,
    BTable,
    flatPickr,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BTab,
    BTabs,
    BPagination,
    BFormSelect,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    BFormSelectOption,
    BModal,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'b-modal': VBModal,
    permission,
  },
  data() {
    return {
      // statusList: ['REC', 'PROD', 'DIP', 'COMP', 'TRA'],
      statusList: [
        { name: 'Order received', code: 'REC' },
        { name: 'In transit', code: 'TRA' },
        { name: 'On Warehouse', code: 'PROD' },
        { name: 'Delivery in progress', code: 'DIP' },
        { name: 'Completed', code: 'COMP' },
      ],
      trackingIDs: '',
      dateConfig: {
        dateFormat: 'Y-m-d H:i:ss',
        mode: 'range',
        defaultDate: [(new Date).setDate((new Date).getDate() - 2), new Date()],
        // enableTime: true, // 启用时间选择器
      },
      condition: {
        page: '1',
        size: '100',
        shipmentDateFrom: null,
        shipmentDateTo: null,
        serviceParty: null,
        orderBy: 'latestOrderStatusTime',
        sequence: 'desc',
        tags: null,
        latestOrderStatus: null,
        trackingIDs: null,
        // orgId: 'org006',
        orgName: null,
        deliveryType: null,
        orderIds: null,
        isAmazon: false,
        customerType: null,
      },
      time: '',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null,
      size: 100,
      totalSize: null,
      totalPage: null,
      rows: [],
      orderStatus: '',
      orderStatusCode: {
        rec: 0, // Order received
        prod: 0, // On Warehouse
        dip: 0, // Delivery in progress
        comp: 0, // Completed
      },
      tkid: '',
      orderId: '',
      selected: '',
      color: 'info',
      color1: 'primary',
      color2: 'danger',
      color3: 'warning',
      tagsList: [],
      sPartyList: [],
      serviceParty: '',
      windowWidth: document.documentElement.clientWidth,  //屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //屏幕高度
      height: document.documentElement.clientHeight*0.67,
      hei: (document.documentElement.clientHeight*0.67-46)/10,
      heiVal: null,
      hei1: document.documentElement.clientHeight*0.12,
      numList: ['Reference Number', 'Tracking Number'],
      numtype: 'Reference Number',
      sonList: [],
      filterBg: {
        '--background': '#fff'
      },
      customerList: [],
      customer: '',
      typeList: [
        { key: 'HD', des: 'Home Delivery' },
        { key: 'SP', des: 'Self Pick' }
      ],
      pkg: false,
      type: null,
      placeholder: 'Enter Order Number to search',
      istkid: false,
      nums: 1,
      idx: null,
      tags: null,
      latestOrderStatus1: '',
      ischeck: false,
      isAllCheck: false,
      isAllCheck1: false,
      check1: true,
      check2: false,
      arrBox: [],
      loadingList: false,
      selectedItem: null,
      jobDetails1: null,
      jobDetails2: null,
      jobDetails3: null,
      jobDetails4: null,
      jobDetails5: null,
      domObj: null,
      isTbox: true,
      cusTypeList: [],
      cusType: '',
      isAccess: true,
    }
  },
  computed: {
  },
  watch: {
    windowHeight(val) {
      // console.log(val)
      this.height = val*0.67
      this.hei = (val*0.67-46)/10
      this.heiVal = val*0.67
      this.hei1 = val*0.12
    },
    windowWidth(val) {
      // console.log('width', val)
    },
    'latestOrderStatus1'(val) {
      this.setBg(val)
    },
    'condition.tags'(val) {
      this.setBg(val)
    },
    'condition.serviceParty'(val) {
      this.setBg(val)
    },
    'type'(val) {
      this.setBg(val)
    },
    'customer'(val) {
      this.setBg(val)
    },
    'cusType'(val) {
      this.setBg(val)
    },
  },
  created() {},
  mounted() {
    this.getList()
    this.configTags()
    this.querySparty()
    this.queryCustomer()
    this.queryCusType()
    this.getAccess()
    setTimeout(() =>{
      this.scrollFunction()
    }, 1000)
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        window.fullWidth = document.documentElement.clientWidth
        this.windowHeight = window.fullHeight
        this.windowWidth = window.fullWidth
      })()
    }
  },
  beforeDestroy() {
    if (!this.domObj) return
    if (this.domObj.attachEvent) {
      this.domObj.detachEvent('onmousewheel', this.mouseScroll)
    }
    if (this.domObj.addEventListener) {
      this.domObj.removeEventListener('DOMMouseScroll', this.mouseScroll, false)
    }
  },
  methods: {
    getAccess() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      if (userData.organizationName !== 'KEC') {
        this.isAccess = false
      }
    },
    scrollFunction () {
      // this.domObj = document.getElementById('cbox')
      this.domObj = this.$refs.cbox
      if (this.domObj.attachEvent) { // IE
      this.domObj.attachEvent('onmousewheel', this.mouseScroll)
      } else if (this.domObj.addEventListener) {
      this.domObj.addEventListener('DOMMouseScroll', this.mouseScroll, false)
      }
      this.domObj.onmousewheel = this.domObj.onmousewheel = this.mouseScroll
    },
    mouseScroll(event) { // google 浏览器下
      // 向上滚 event.wheelDelta = 120
      // 向下滚 event.wheelDelta = -120
      if (event.wheelDelta > 0){
        this.isTbox = true
      } else {
        this.isTbox = false
      }
    },
    // filter图标背景颜色设置
    setBg(val) {
      if (val !== null) {
        this.filterBg= {
          '--background': '#ff9f43'
        }
      } else {
        this.filterBg= {
          '--background': '#fff'
        }
      }
    },
    // 默认日期格式转换
    dateFormats() {
      const fromDate = new Date()
      let datetime = fromDate.getFullYear() + "-" +
      (fromDate.getMonth()> 8 ? (fromDate.getMonth() + 1) : "0" + (fromDate.getMonth() + 1)) + "-"
      + (fromDate.getDate()> 9 ? (fromDate.getDate()) : "0" + (fromDate.getDate()))
      + ' ' + '23:59:59'

      const toDate = new Date((new Date).setDate((new Date).getDate() - 2))
      let datetime1 = toDate.getFullYear() + "-" +
      (toDate.getMonth()> 8 ? (toDate.getMonth() + 1) : "0" + (toDate.getMonth() + 1)) + "-"
      + (toDate.getDate()> 9 ? (toDate.getDate()) : "0" + (toDate.getDate()))
      + ' ' + '00:00:00'

      this.condition.shipmentDateFrom = datetime1
      this.condition.shipmentDateTo = datetime
    },
    // 查询列表
    queryList() {
      this.loadingList = true
      this.$http.post('/jobkes/search', this.condition).then(res => {
        // console.log(res.data.data)
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.getStatusCount()
        this.loadingList = false
        // if (this.rows.length > 0) {
        //   this.onDetail(this.rows[0])
        // } else {
        //   this.onDetail(null)
        // }
      }).catch(error => console.log(error))
    },
    // 首次查询
    getList() {
      this.dateConfig.dateFormat = 'Y-m-d'
      this.dateFormats()
      this.queryList()
    },
    // 根据日期查询
    onChange: debounce(function() {
      // this.dateConfig.dateFormat = 'Y-m-d H:i:ss'
      const dd = this.time.split('to ')
      if (dd[0] !== '') {
        this.condition.shipmentDateFrom = (dd[1] !== undefined)? (dd[0] + '00:00:00') : (dd[0] + ' 00:00:00')
        this.condition.shipmentDateTo = ((dd[1] !== undefined)? dd[1] : dd[0])  + ' 23:59:59'
      } else {
        this.condition.shipmentDateFrom = null
        this.condition.shipmentDateTo = null
      }
      this.queryList()
    }, 1000),
    // 根据ID查询
    searchById() {
      this.selected = ''
      if (this.trackingIDs.trim() !== ''){
        this.condition.shipmentDateFrom = null
        this.condition.shipmentDateTo = null
        this.condition.page = '1'
        if (this.istkid) {
          this.condition.trackingIDs = this.trackingIDs.trim().split(/[(\r\n)\r\n]+/)
          this.condition.orderIds = null
        } else {
          this.condition.orderIds = this.trackingIDs.trim().split(/[(\r\n)\r\n]+/)
          this.condition.trackingIDs = null
        }
      } else {
        if (this.time !== '') {
          this.onChange()
        } else {
          this.dateFormats()
        }
        this.condition.trackingIDs = null
        this.condition.orderIds = null
      }
      this.queryList()
    },
    handleChangePage(page) {
      this.condition.page = page.toString()
      this.nums = 1
      this.queryList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.queryList()
    },
    onClear() {
      this.latestOrderStatus1 = null
      this.condition.tags = null
      this.tags = null
      this.condition.serviceParty = null
      this.condition.orgName = null
      this.pkg = null
      this.type = null
      this.condition.deliveryType = null
      this.cusType = null
      this.condition.customerType = null
    },
    // 点击查看详情
    onDetail(obj) {
      if (obj) {
        // this.tkid = obj.tkID
        // this.orderId = obj.orderID
        this.selected = obj.tkID
        this.selectedItem = obj
        this.getjobDetails(obj.orderID)
        this.searchJobkes(obj.tkID)
        this.jobTrack1(obj.tkID)
        // this.jobTrack2(obj.tkID)
        this.getPackageProducts(obj.orderID)
      } else {
        this.tkid = ""
        this.orderId = ""
        this.selected = ""
        this.selectedItem = null
      }
    },
    getjobDetails(oId) {
      this.$http.get('/jobkes/getjobDetails', { params: { orderId: oId } }).then(res => {
        this.jobDetails1 = res.data.data
      })
    },
    searchJobkes(tkid){
      const para = {}
      para.page = '1'
      para.size = '10'
      para.isAmazon = false
      para.trackingIDs = tkid.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobkes/search', para).then(res => {
        if (res.data.data.content.length !== 0){
          this.jobDetails2 = res.data.data.content[0]
        }
      })
    },
    jobTrack1(tkid){
      const para = {}
      para.page = '1'
      para.size = '10'
      para.startEventTime = null
      para.endEventTime = null
      para.statusCode = null
      para.isDisplay = null
      para.orderBy = 'id'
      para.orderMole = '0'
      para.tkIDs = tkid.split(/[(\r\n)\r\n]+/)
      this.$http.post('/jobTrack/search', para).then(res => {
        this.jobDetails3 = res.data.data.content
      })
    },
    jobTrack2(tkid){
      this.$http.get('/jobTrack/ByOrderStatus', { params:{ tkID: tkid } }).then(res => {
        this.jobDetails4 = res.data.data
      })
    },
    getPackageProducts(oId){
      this.$http.get('/jobkes/getPackageProducts', { params: { orderId: oId } }).then(res => {
        this.jobDetails5 = res.data.data
      })
    },
    // query tags
    configTags() {
      // this.$http.get('/configTags/findALLConfigTagsByisEnabled').then(res => {
      //   this.tagsList = res.data.data
      // })
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'tags'}}).then(res => {
        this.tagsList = res.data.data
      })
    },
    selTag(t) {
      const arr = []
      t.map(item => {
        arr.push(item.codeName)
      })
      this.condition.tags = arr
    },
    selStatus(t) {
      this.condition.latestOrderStatus = t.code
    },
    // query service party
    querySparty() {
      // this.$http.get('jobkes/getServicePartyList').then(res => {
      //   this.sPartyList = res.data.data
      // })
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'serviceParty'}}).then(res => {
        this.sPartyList = res.data.data
      })
    },
    selSParty(d) {
      // this.condition.serviceParty = p.serviceParty
      this.condition.serviceParty = d.codeName
      this.serviceParty = d.codeDescription
    },
    queryCustomer() {
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
        this.customerList = res.data.data
      })
    },
    selCustomer(d) {
      this.customer = d.name
      this.condition.orgName = d.name
    },
    queryCusType() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'orgType'}}).then(res => {
        this.cusTypeList = res.data.data
      })
    },
    selCusType(d) {
      this.cusType = d.codeDescription
      this.condition.customerType = d.codeName
    },
    // 查询status次数
    getStatusCount1() {
      this.$http.post('jobkes/getTotalStatusList', this.condition).then(res => {
        const result = res.data.data
        this.orderStatusCode.comp = (result.COMP) !== 'undefined' ? result.COMP : 0
        this.orderStatusCode.rec = (result.REC) !== 'undefined' ? result.REC : 0
        this.orderStatusCode.dip = (result.DIP) !== 'undefined' ? result.DIP : 0
        this.orderStatusCode.prod = (result.PROD) !== 'undefined' ? result.PROD : 0
      })
    },
    getStatusCount() {
      if (this.trackingIDs.trim() !== ''){
        this.condition.shipmentDateFrom = null
        this.condition.shipmentDateTo = null
        this.getStatusCount1()
      } else {
        this.getStatusCount1()
      }
    },
    selnum(d) {
      this.numtype = d
      if (d === 'Tracking Number') {
        this.placeholder = 'Enter Tracking Number to search'
        this.istkid = true
      } else if (d === 'Reference Number') {
        this.placeholder = 'Enter Order Number to search'
        this.istkid = false
      }
    },
    selType(d) {
      this.condition.deliveryType = d.key
      this.type = `${d.key}(${d.des})`
    },
    selSonList(d,i) {
      console.log(d,i)
      this.tkid = d.tkID
      this.orderId = d.orderID
      this.nums = d.pkSN
      this.idx = i
    },
    // 全选 全不选
    selectedAll() {
      this.rows.map(i=>{
        !this.isAllCheck? (i.choose=false) : (i.choose=true)
      })
    },
    selectedAll1() {
      if (this.isAllCheck1) {
        this.check1 = true
        this.check2 = false
      }
    },
    itemCheck(item){
      let arrTrue=[]
      let arrFalse=[]
      this.rows.map(i=>{
        if (i.choose) {
          arrTrue.push(i.choose)
        } else {
          arrFalse.push(i.choose)
        }
      })
      if (arrTrue.length === this.rows.length) {
        this.isAllCheck = true
        this.check1 = true
        this.check2 = false
      }
      if (arrFalse.length >0) {
        // this.isAllCheck = false
        this.check1 = false
        this.check2 = true
      }
      if (arrFalse.length === this.rows.length) {
        this.isAllCheck = false
        this.check1 = true
        this.check2 = false
      }
      const obj = {}
      obj.customerOrderNo = item.customerOrderNo
      obj.tkID = item.tkID
      obj.labelUrl= (item.updateLabelUrl!== null) ? item.updateLabelUrl : item.labelUrl
      obj.orderQty = item.orderQty
      this.arrBox.push(obj)

    },
    // 批量下载跳转
    batchDownload() {
      this.$http.post('/exportReport/labelUrlReport', this.arrBox).then(res=>{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'batch-download' })
      })
    },
  },
}
</script>
<style scoped>
.ll-width{
  width: 400px;
}
.ll-cardHei1>.card-body{
  padding: 0;
}
.ll-sHei{
  height: 62vh;
}
@media (min-width: 2000px) {
  .ll-sHei {
    height: 70vh;
  }
}
.ll-tBox{
  /* position: fixed; */
  height: 46px;
  z-index: 9;
  /* width: 400px; */
  background-color: #fff;
  padding-left: 0.5rem;
}
.ll-cbox{
  /* margin-top: 46px; */
  overflow-y: auto;
}
.ll-batch{
  /* position: absolute;
  right: 14rem;
  z-index: 9;
  top: 6rem; */
  margin-left: 1rem;
  padding: 0.5rem 0;
}
.ll-badge{
  /* padding: 2px; */
  margin-right: 2px;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-searchBtn div:active{
  opacity: 0.8;
}
.ll-fHei{
  height: 124px;
  /* height: var(--height); */
  margin-bottom: 10px;
}
.icon{
  width: 3em;
  height: 3em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.ll-conBox{
  display: flex;
  align-items: center;
}
.ll-box{
  margin-left: 10px;
}
.ll-box span{
  display: block;
  text-align: center;
}
.ll-newbox{
  text-align: center;
}
.ll-newbox .ll-tit{
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
}
.ll-searchBox{
  margin-top: 10px;
}
.ll-fHei>.card-body{
  display: flex;
  align-items: center;
}
.ll-cardBody{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ll-card>.card-body{
  padding: 0;
}
.ll-tabs .nav-pills .nav-link.active{
  background-color: unset;
  color: #5e5873;
  box-shadow: none;
  border: none;
  border-bottom: 3px solid #ed6d01;
  border-radius: 0px;
}
.ll-tabs .nav-item{
  font-weight: bold;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-tkid{
  color: #161d31;
}
.ll-cardList{
  margin-bottom: 5px;
}
.ll-cardList .card-header{
  padding: 0.5rem;
}
.ll-cardList .ll-cardBody{
  padding: 0.5rem;
}
.ll-cardHei{
  /* height: 100%; */
  overflow-y: auto;
}
.ll-page{
  position: absolute;
  bottom: -10px;
  /* left: 15%; */
}
.ll-cardActive{
  background: #f6f6f6;
}
.ll-backg{
  background: #f6f6f6;
}
.ll-0+ div{
  display: none;
}
.ll-rowbox{
  width: 100%;
  margin-left: 0;
}
.ll-rowbox .ll-col1{
  text-align: left;
  padding: 0;
}
.ll-rowbox .ll-col2{
  text-align: center;
}
.ll-rowbox .ll-col3{
  text-align: right;
}
.ll-custom{
  padding: 0 10px;
}
.ll-numSel{
  height: auto;
}
.ll-pg{
  width: 30%;
}
.ll-outline> div{
  background: var(--background);
  margin-right: 1px;
}
.ll-hover{
  cursor: pointer;
}
/* .ll-hover:hover{
  color: #ed6d01;
} */
.ll-fontbold{
  font-weight: bold;
}
</style>
<style>
.ll-tabs .nav-item{
  font-weight: bold;
}
.ll-collapse .card{
  box-shadow: none;
}
.ll-text{
  margin: auto;
}
[dir] .custom-select{
  border-radius: 0;
}
[dir] .input-group:not(.bootstrap-touchspin):focus-within .form-control,
[dir] .input-group:not(.bootstrap-touchspin):focus-within .input-group-text,
[dir] .input-group:not(.bootstrap-touchspin):focus-within .custom-select{
  border-color: #ed6d01;
  box-shadow: none;
}
.ll-row{
  flex-wrap: nowrap;
}
</style>